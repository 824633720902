import LanguageMenu from '@/app/ui-new/components/LanguageMenu/LanguageMenu'
import PrimaryButton from '@/app/ui-new/components/PrimaryButton/PrimaryButton'
import React, { useEffect } from 'react'
import SecondaryButton from '@/app/ui-new/components/SecondaryButton/SecondaryButton'
import i18n from './WebsiteHeaderDesktop.i18n'
import { Box, Grid as GridMaterial } from '@material-ui/core'
import { Header, Grid, Logo, Link } from './WebsiteHeaderDesktop.styled'
import { User } from '@/app/model/User'
import { setRequestDemoDialogOpen as reduxSetRequestDemoOpen } from '@/app/ui/redux/actions/RequestDemoActions'
import { useDispatch } from 'react-redux'
import { useI18n } from '@/app/ui/components/hooks/I18n'

const WebsiteHeaderDesktop: React.FunctionComponent<HeaderProps> = (props) => {
    const { t } = useI18n(i18n)
    const dispatch = useDispatch()

    const openRequestDemoDialog = () => {
        dispatch(reduxSetRequestDemoOpen(true))
    }

    const isGmx = !!window.document.URL.endsWith('/gmx')
    const isCoc = !!window.document.URL.endsWith('/commerce-chamber')

    const isSupplier = window.location.pathname.match(/^\/register\/[^/]+\/supplier$/)

    useEffect(() => {
        if (isCoc) {
            localStorage.setItem('create-your-account', '/commerce-chamber')
        } else if (isGmx) {
            localStorage.setItem('create-your-account', '/gmx')
        }
    }, [isGmx, isCoc])

    return (
        <Header>
            <Grid container alignItems='center'>
                <Grid item xs={2}>
                    <Link to='/'>
                        <Logo />
                    </Link>
                </Grid>
                <Grid item xs={10}>
                    <GridMaterial item container xs={12} justifyContent='flex-end' alignItems='center' spacing={3}>
                        <Grid item>
                            <LanguageMenu user={props.user} />
                        </Grid>

                        {props.user ? (
                            <Grid item>
                                <SecondaryButton variant='outlined' href='/new/orders'>
                                    {t('orders')}
                                </SecondaryButton>
                            </Grid>
                        ) : (
                            !isSupplier && (
                                <>
                                    <Grid item>
                                        <PrimaryButton size={'small'} href={`/create-your-organization${localStorage.getItem('create-your-account') ? localStorage.getItem('create-your-account') : ''}`}>
                                            {t('Create your Account')}
                                        </PrimaryButton>
                                    </Grid>
                                    <Grid item>
                                        <SecondaryButton size={'small'} variant='outlined' href='/signin'>
                                            {t('sign in')}
                                        </SecondaryButton>
                                    </Grid>
                                </>

                            ))}
                    </GridMaterial>
                </Grid>
            </Grid>
        </Header>
    )
}

WebsiteHeaderDesktop.displayName = 'Header'
export { WebsiteHeaderDesktop }

export interface HeaderProps {
    user: User | null
}
