import React from 'react'
import moment from 'moment'
import { AsYouType, CountryCode as PhoneCountryCode } from 'libphonenumber-js'
import * as EmailValidator from 'email-validator'
import { Country as CountryCode, UuidV4 } from '@lazr/openapi-client'
import { validatePostalCode } from '../../../lib/helpers'
import { Country } from '../../../../model'
// import { CollectAccount } from '@/app/model/Address'
import { CollectAccountType } from '@lazr/enums'

interface AddressErrors {
    shippingContactEmails?: string
    shippingContactPhone?: string
    billingContactEmails?: string
    billingContactPhone?: string
    userSpecifiedId?: string
    streetAddressLine1?: string
    city?: string
    state?: string
    country?: string
    postalCode?: string
    companyName?: string
    shippingContactName?: string
    billingContactName?: string
    openTime?: string
    closeTime?: string
    isBilling?: string
    collectAccounts?: string
}

interface Account {
    id?: UuidV4
    tempId?: string
    carrier: {
        id?: UuidV4
        name?: string
    }
    accountNumber: string
    accountType: CollectAccountType
}

interface AddressValidationFields {
    shippingContactEmails?: string[]
    shippingContactPhone?: string
    billingContactEmails?: string[]
    billingContactPhone?: string
    userSpecifiedId?: string
    streetAddressLine1?: string
    city?: string
    state?: string
    country?: Country | null
    postalCode?: string
    companyName?: string
    shippingContactName?: string
    billingContactName?: string
    openTime?: string
    closeTime?: string
    isShipping?: boolean
    isBilling?: boolean
    collectAccounts?: Account[]
}

type AddressIsDirty = Partial<Record<keyof AddressValidationFields, boolean>>

const checkAddressErrors = (addressErrors: AddressErrors): boolean => Object.keys(addressErrors).length !== 0

export const useFormValidation = (
    countryCode: CountryCode,
    validator: (
        addressValidationFields: AddressValidationFields,
        addressIsDirty: AddressIsDirty,
        isSubmitting: boolean,
        countryCode: CountryCode,
    ) => { addressErrors: AddressErrors; addressIsDirty: AddressIsDirty }
):
    [(addressValidationFields: AddressValidationFields, isSubmitting?: boolean) => boolean, boolean, AddressErrors] => {
    const [ isSubmitted, setSubmitted ] = React.useState(false)
    const [ isValidating, setValidating ] = React.useState(false)
    const [ errors, setErrors ] = React.useState<AddressErrors>({})
    const [ addressIsDirty, setAddressIsDirty ] = React.useState<AddressIsDirty>({})

    const validate = (addressValidationFields: AddressValidationFields, isSubmitting = false): boolean => {
        if (!isSubmitting && !isSubmitted) {
            return false
        }
        setValidating(true)
        if (isSubmitting) {
            setSubmitted(isSubmitting)
        }
        const { addressIsDirty: newAddressIsDirty, addressErrors } = validator(
            addressValidationFields,
            addressIsDirty,
            isSubmitting,
            countryCode
        )

        setAddressIsDirty(newAddressIsDirty)
        setErrors(addressErrors)
        const hasErrors = checkAddressErrors(addressErrors)

        setValidating(false)

        return hasErrors
    }

    return [ validate, isValidating, errors ]
}

export default useFormValidation

export const newAddressValidator = (
    addressValidationFields: AddressValidationFields,
    addressIsDirty: AddressIsDirty,
    isSubmitting: boolean,
    countryCode: CountryCode
): { addressErrors: AddressErrors; addressIsDirty: AddressIsDirty } => {
    const addressErrors: AddressErrors = {}
    const requiredText = 'Required'

    if (isSubmitting) {
        addressIsDirty = {
            shippingContactEmails: true,
            shippingContactPhone: true,
            billingContactEmails: true,
            billingContactPhone: true,
            userSpecifiedId: true,
            streetAddressLine1: true,
            city: true,
            state: true,
            country: true,
            postalCode: true,
            companyName: true,
            shippingContactName: true,
            billingContactName: true,
            openTime: true,
            closeTime: true,
        }
    }

    if (!addressValidationFields.userSpecifiedId?.trim() && addressIsDirty.userSpecifiedId) {
        addressErrors.userSpecifiedId = requiredText
    }
    if (addressValidationFields.userSpecifiedId?.trim() && !addressIsDirty.userSpecifiedId) {
        addressIsDirty.userSpecifiedId = true
    }

    if (!addressValidationFields.country && addressIsDirty.country) {
        addressErrors.country = requiredText
    }
    if (addressValidationFields.country && !addressIsDirty.country) {
        addressIsDirty.country = true
    }

    if ((addressValidationFields.country && ['CA', 'US', 'DE', 'AT', 'CH', 'ES', 'BR', 'NG'].includes(addressValidationFields.country.code)) &&  !addressValidationFields.state?.trim() && addressIsDirty.state) {
        addressErrors.state = requiredText
    } else if (addressValidationFields.state && addressIsDirty.state && addressValidationFields.state.length > 2) {
        addressErrors.state = 'Invalid state code. State must use the two letter code.'
    }
    if (addressValidationFields.state?.trim() && !addressIsDirty.state) {
        addressIsDirty.state = true
    }

    if (!addressValidationFields.city?.trim() && addressIsDirty.city) {
        addressErrors.city = requiredText
    }
    if (addressValidationFields.city?.trim() && !addressIsDirty.city) {
        addressIsDirty.city = true
    }

    if (!addressValidationFields.streetAddressLine1?.trim() && addressIsDirty.streetAddressLine1) {
        addressErrors.streetAddressLine1 = requiredText
    }
    if (addressValidationFields.streetAddressLine1?.trim() && !addressIsDirty.streetAddressLine1) {
        addressIsDirty.streetAddressLine1 = true
    }

    if (!addressValidationFields.companyName?.trim() && addressIsDirty.companyName) {
        addressErrors.companyName = requiredText
    }
    if (addressValidationFields.companyName?.trim() && !addressIsDirty.companyName) {
        addressIsDirty.companyName = true
    }

    if (!addressValidationFields.postalCode?.trim() && addressIsDirty.postalCode) {
        addressErrors.postalCode = requiredText
    } else if (addressValidationFields.postalCode && addressValidationFields.country && addressIsDirty.postalCode) {
        if (!validatePostalCode(addressValidationFields.country.code, addressValidationFields.postalCode)) {
            addressErrors.postalCode = 'Invalid postal/zip code'
        }
    }
    if (addressValidationFields.postalCode?.trim() && !addressIsDirty.postalCode) {
        addressIsDirty.postalCode = true
    }

    if (!addressValidationFields.openTime?.trim() && addressIsDirty.openTime) {
        addressErrors.openTime = requiredText
    } else if ((!moment(addressValidationFields.openTime, 'HH:mm', true).isValid() &&
            !moment(addressValidationFields.openTime, 'HH:mm:ss', true).isValid()) &&
            addressIsDirty.openTime
    ) {
        addressErrors.openTime = 'Invalid time'
    }
    if (addressValidationFields.openTime?.trim() && !addressIsDirty.openTime) {
        addressIsDirty.openTime = true
    }

    if (!addressValidationFields.closeTime?.trim() && addressIsDirty.closeTime) {
        addressErrors.closeTime = requiredText
    } else if ((!moment(addressValidationFields.closeTime, 'HH:mm', true).isValid() &&
            !moment(addressValidationFields.closeTime, 'HH:mm:ss', true).isValid()) &&
            addressIsDirty.closeTime
    ) {
        addressErrors.closeTime = 'Invalid time'
    }
    if (addressValidationFields.closeTime?.trim() && !addressIsDirty.closeTime) {
        addressIsDirty.closeTime = true
    }
    if (!addressValidationFields.isShipping && !addressValidationFields.isBilling) {
        addressErrors.isBilling = 'Invalid address type. Select at least one.'
    }
    if (addressValidationFields.isShipping) {
        if (!addressValidationFields.shippingContactName?.trim() && addressIsDirty.shippingContactName) {
            addressErrors.shippingContactName = requiredText
        }
        if (addressValidationFields.shippingContactName?.trim() && !addressIsDirty.shippingContactName) {
            addressIsDirty.shippingContactName = true
        }
        if (addressValidationFields.shippingContactEmails?.length === 0 && addressIsDirty.shippingContactEmails) {
            addressErrors.shippingContactEmails = requiredText
        } else if (
            addressValidationFields.shippingContactEmails && addressValidationFields.shippingContactEmails.length > 0
            && (!addressValidationFields.shippingContactEmails
                .every((shippingContactEmail) =>  EmailValidator.validate(shippingContactEmail)))
            && addressIsDirty.shippingContactEmails
        ) {
            addressErrors.shippingContactEmails = 'Invalid email addresses'
        }
        if (!addressValidationFields.shippingContactEmails ||
            addressValidationFields.shippingContactEmails?.length === 0 ||
            !addressValidationFields.shippingContactEmails
                .every((shippingContactEmail) =>  EmailValidator.validate(shippingContactEmail))) {
            addressIsDirty.shippingContactEmails = true
        }

        if (!addressValidationFields.shippingContactPhone?.trim() && addressIsDirty.shippingContactPhone) {
            addressErrors.shippingContactPhone = requiredText
        } else if (addressValidationFields.shippingContactPhone && addressIsDirty.shippingContactPhone) {
            const asYouType = new AsYouType(countryCode as PhoneCountryCode)
            asYouType.input(addressValidationFields.shippingContactPhone)
            if (!asYouType.getNumber()?.isPossible()) {
                addressErrors.shippingContactPhone = 'Invalid phone number'
            }
        }
        if (addressValidationFields.shippingContactPhone?.trim() && !addressIsDirty.shippingContactPhone) {
            addressIsDirty.shippingContactPhone = true
        }

        if (addressValidationFields.collectAccounts?.length === 0 && addressIsDirty.collectAccounts) {
            addressErrors.collectAccounts = requiredText
        } else if (
            addressValidationFields.collectAccounts && addressValidationFields.collectAccounts.length > 0
            && (!addressValidationFields.collectAccounts
                .every((account) =>  account.accountType && account.accountNumber &&
                    account.carrier && account.carrier.id && account.carrier.name))
        ) {
            addressErrors.collectAccounts = 'Invalid collect account(s)'
        }

        if (!addressValidationFields.collectAccounts ||
            addressValidationFields.collectAccounts?.length === 0 ||
            !addressValidationFields.collectAccounts
                .every((account) => account.accountType && account.accountNumber &&
                    account.carrier && account.carrier.id && account.carrier.name)) {
            addressIsDirty.collectAccounts = true
        }
    }
    if (addressValidationFields.isBilling) {
        if (!addressValidationFields.billingContactName?.trim() && addressIsDirty.billingContactName) {
            addressErrors.billingContactName = requiredText
        }
        if (addressValidationFields.billingContactName?.trim() && !addressIsDirty.billingContactName) {
            addressIsDirty.billingContactName = true
        }
        if (addressValidationFields.billingContactEmails?.length === 0 && addressIsDirty.billingContactEmails) {
            addressErrors.billingContactEmails = requiredText
        } else if (
            addressValidationFields.billingContactEmails &&  addressValidationFields.billingContactEmails.length > 0
            && (!addressValidationFields.billingContactEmails.every((billingContactEmail) =>  EmailValidator.validate(billingContactEmail)))
            && addressIsDirty.billingContactEmails
        ) {
            addressErrors.billingContactEmails = 'Invalid email addresses'
        }
        if (!addressValidationFields.billingContactEmails ||
            addressValidationFields.billingContactEmails?.length === 0 ||
            !addressValidationFields.billingContactEmails.every((billingContactEmail) =>  EmailValidator.validate(billingContactEmail))) {
            addressIsDirty.billingContactEmails = true
        }

        if (!addressValidationFields.billingContactPhone?.trim() && addressIsDirty.billingContactPhone) {
            addressErrors.billingContactPhone = requiredText
        } else if (addressValidationFields.billingContactPhone && addressIsDirty.billingContactPhone) {
            const asYouType = new AsYouType(countryCode as PhoneCountryCode)
            asYouType.input(addressValidationFields.billingContactPhone)
            if (!asYouType.getNumber()?.isPossible()) {
                addressErrors.billingContactPhone = 'Invalid phone number'
            }
        }
        if (addressValidationFields.billingContactPhone?.trim() && !addressIsDirty.billingContactPhone) {
            addressIsDirty.billingContactPhone = true
        }
    }

    return { addressErrors, addressIsDirty }
}
